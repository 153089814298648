<template>
    <UserListCompo :isAdmin="true" />
</template>

<script>
export default {
    inject: ["setRightSideHidden"],
    components: {
    },
    props: {},
    data() {
        return {
        };
    },
    watch: {
    },
    created() { },
    mounted() {
        this.setRightSideHidden(false)
        this.initLocalStorage();        
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
    },
    computed: {
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.tblScroll{
    max-height: 655px;
    .tbl{
        border-bottom: 0;
    }
    
}

</style>

